/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import listItem from '@/components/common/ListItem';
import router from '@/router/index';
Vue.component(listItem.name, listItem);
export default {
  data() {
    return {
      messageList: [],
      messageObject: {
        infInnerMsg: {
          sender: "",
          content: ""
        }
      }
    };
  },
  components: {},
  props: {
    dataType: String,
    hideRed: Boolean
  },
  methods: {
    sendMessage() {
      router.push({
        name: 'sendMsg'
      });
    },
    getAllMsgList() {
      var url = _baseUrl + "/mobile/oa/get" + [this.dataType] + ".do";
      this.$ajax({
        url: url,
        method: 'POST'
      }).then(response => {
        this.messageList = response.data.data.reverse();
      }).catch(error => {
        console.log(error);
      });
    },
    showDialog(message, listType) {
      this.messageObject = message;
      var url = _baseUrl + "/mobile/oa/updateStatus.do";
      this.$ajax({
        url: url,
        method: 'POST',
        params: {
          pkId: this.messageObject.msgId
        }
      }).then(response => {
        if (listType == 'allList') {
          this.getAllMsgList();
          this.$store.dispatch('getCountNum', this); //更新vuex数据
        }
      }).catch(error => {
        console.log(error);
      });
      this.$emit("show-msg");
      this.$store.commit('messagePopup', {
        "message": message,
        "listType": this.dataType
      });
    },
    getFirstLetter(word) {
      return word.substr(0, 1).toUpperCase();
    }
  },
  created() {
    this.getAllMsgList();
  }
};