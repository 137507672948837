/* this file is transformed by vux-loader */
/* eslint-disable */
export default {
  name: 'popup-list',
  data() {
    return {};
  },
  props: {},
  computed: {
    data: function () {
      return this.$store.state.pageModule.messagePopup;
    }
  },
  methods: {
    hideMsg() {
      this.$emit("hide-msg");
    }
  }
};