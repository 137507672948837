/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import Popuplist from '@/components/oa/Popup';
import router from '@/router/index';
import Move from '@/components/Move';
import MessageList from '@/components/oa/MessageList';
Vue.component(Popuplist.name, Popuplist);
export default {
  data() {
    return {
      showDialogWin: false,
      iconList: {
        icon: {
          1: "unread",
          2: "sended"
        },
        title: {
          1: "消息列表",
          2: "已发消息"
        }
      }
    };
  },
  components: {
    Move,
    MessageList
  },
  methods: {
    sendMessage() {
      router.push({
        name: 'sendMsg'
      });
    },
    hideMsg() {
      this.showDialogWin = false;
    },
    getFirstLetter(word) {
      return word.substr(0, 1).toUpperCase();
    },
    showPopup() {
      this.showDialogWin = true;
    }
  },
  created() {},
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$refs.move.obtainIndex(to.fullPath);
    });
  },
  beforeRouteLeave(to, from, next) {
    if (to.meta.layer > from.meta.layer) {
      this.$refs.move.keepIndex(from.fullPath);
    } else {
      this.$refs.move.keepIndex(from.fullPath, "empty");
    }
    next();
  }
};